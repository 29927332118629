<template>
  <div v-if="item">
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("Account.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>mdi-store</v-icon>
      <template v-slot:extension>
        <v-tabs v-model="tabs" align-with-title>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab key="tab1">{{ $t("Account.tabInfo") }}</v-tab>
          <v-tab key="tab2">{{ $t("Account.tabUsers") }}</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tabs">
      <!-- TAB1 -->
      <v-tab-item>
        <v-card flat>
          <v-form ref="form">
            <div class="mb-4 mt-4">
              <v-btn
                color="info"
                v-show="!editMode"
                class="mr-4"
                @click="enableEditMode(true)"
              >
                {{ $t("btnEdit") }}</v-btn
              >
              <v-btn
                class="mr-4"
                color="error"
                v-show="editMode"
                @click="reset()"
              >
                {{ $t("btnCancel") }}</v-btn
              >
              <v-btn color="success" v-show="editMode" @click="submitForm()">
                {{ $t("btnSave") }}</v-btn
              >
            </div>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  :label="$t('Account.name')"
                  filled
                  dense
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  v-model="item.name"
                  required
                  :disabled="!editMode"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                  :label="$t('Account.country')"
                  filled
                  dense
                  v-model="item.country"
                  :items="$t('CountryCodeList')"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :hint="$t('Account.country_hint')"
                  required
                  :disabled="!editMode"
                  :readonly="editMode"
                ></v-select>
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                  :label="$t('Account.status')"
                  filled
                  dense
                  v-model="item.status"
                  :items="$t('AccountEnums.StatusArray')"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :hint="$t('Account.status_hint')"
                  required
                  :disabled="!editMode"
                ></v-select>
                <!-- :readonly="editMode && !displayOnlyForAdmin" -->
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  :label="$t('Account.email')"
                  filled
                  dense
                  v-model="item.email"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  required
                  :disabled="!editMode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  :label="$t('Account.companyIdNumber')"
                  filled
                  dense
                  v-model="item.companyIdNumber"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :hint="$t('Account.companyIdNumber_hint')"
                  required
                  :disabled="!editMode"
                  :readonly="editMode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  :label="$t('Account.companyTaxId')"
                  filled
                  dense
                  v-model="item.companyTaxId"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  required
                  :disabled="!editMode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  :label="$t('Account.companyVatPayer')"
                  filled
                  dense
                  v-model="item.companyVatPayer"
                  :items="$t('AccountEnums.CompanyVatPayer')"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  required
                  :disabled="!editMode"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  :label="$t('Account.companyVatId')"
                  filled
                  dense
                  :required="item.companyVatPayer == 'VAT'"
                  :rules="
                    item.companyVatPayer == 'VAT'
                      ? [(v) => !!v || $t('msgRequiredField')]
                      : []
                  "
                  v-model="item.companyVatId"
                  :disabled="!editMode || item.companyVatPayer != 'VAT'"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <my-datepicker
                  :label="$t('Account.validFrom')"
                  v-model="item.validFrom"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  dense
                  filled
                  required
                  :disabled="!editMode"
                  :readonly="editMode && !displayOnlyForAdmin"
                >
                </my-datepicker>
              </v-col>
              <v-col cols="12" md="3">
                <my-datepicker
                  :label="$t('Account.validTo')"
                  v-model="item.validTo"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  dense
                  filled
                  :disabled="!editMode"
                >
                </my-datepicker>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  :label="$t('Account.companyName')"
                  filled
                  dense
                  v-model="item.companyName"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  required
                  :disabled="!editMode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  :label="$t('Account.companyAddress1')"
                  filled
                  dense
                  v-model="item.companyAddress1"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  required
                  :disabled="!editMode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  :label="$t('Account.companyAddress2')"
                  filled
                  dense
                  v-model="item.companyAddress2"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  required
                  :disabled="!editMode"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  :label="$t('Account.companyAddress3')"
                  filled
                  dense
                  v-model="item.companyAddress3"
                  :disabled="!editMode"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <!-- TAB2 -->
        <v-card flat>
          <account-client-list :accountId="itemId" />
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>

  <div v-else>
    <v-btn color="error" @click="getItem()">{{ $t("btnReload") }}</v-btn>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import { Status, StatusAccountColor } from "../enums/AccountEnum";
import { Country } from "../enums/CountryEnum";
import AccountClientList from "../components/AccountClientList.vue";

export default {
  components: {
    AccountClientList,
  },
  data() {
    return {
      tabs: null,
      displayOnlyForAdmin: false,
      //enums
      Status,
      countryEnum: Country,
      StatusAccountColor,
      //
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },

      editMode: false,
      itemId: 0,
      item: null,
      cachedItem: null,
    };
  },
  //computed:{
  //formatDate() {
  //return moment(this.item.validFrom).local();
  //}
  //},
  created() {},

  mounted() {
    this.getUserRole();
    this.itemId = this.$route.params.id;
    this.getItem(this.itemId);
  },

  methods: {
    enableEditMode(enabled) {
      this.editMode = enabled;
    },
    getUserRole() {
      let enabledRoles = ["ADMIN", "SUPPORT"];
      let userRoles = this.$store.state.user.roleList;
      this.displayOnlyForAdmin =
        userRoles.some((a) => (enabledRoles.includes(a) ? true : false)) ===
        true;
    },
    getItem(itemId) {
      axios
        //.get(process.env.VUE_APP_API_BASE_URL + `/dispatching/vehicle/${this.itemId}`)
        .get(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/taxiservice-account`
        )
        .then((response) => {
          var itemsAll = response.data;
          var item = itemsAll.find((item) => {
            return item.accountId == itemId;
          });
          this.item = item;
          this.cachedItem = Object.assign({}, this.item);
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },

    reset() {
      this.enableEditMode(false);
      //this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.item = Object.assign({}, this.cachedItem);
    },

    submitForm() {
      const isValid = this.$refs.form.validate();
      if (isValid) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/taxiservice-account`,
            this.item
          )
          .then((response) => {
            this.item = response.data;
            this.getItem(this.itemId);
            this.enableEditMode(false);

            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateSuccess");
            this.snackbar.color = "success";
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateError");
            this.snackbar.color = "error";
          });
      }
    },
  },
};
</script>
