<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("VehicleList.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>fa-car</v-icon>
    </v-toolbar>

    <v-btn small class="my-2" color="success" @click.stop="openCreateDialog()">
      {{ $t("btnCreate") }}
    </v-btn>

    <v-data-table
      :loading="loadingData"
      :headers="gridHeaders"
      :items="gridItems"
      class="elevation-1"
      sort-by="internalName"
      :mobile-breakpoint="mobileBreakpointTable"
      dense
      sort-desc
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [15, 25, 50, 100, -1],
        showFirstLastPage: true,
      }"
      ><!-- Filtrovanie tabulky -->
      <template
        v-slot:[`body.prepend`]
        v-if="$vuetify.breakpoint.width > mobileBreakpointTable"
      >
        <tr>
          <td>
            <v-icon class="px-3">mdi-filter</v-icon>
          </td>
          <td>
            <v-text-field
              v-model="filter.internalName"
              type="text"
              clearable
              :label="$t('VehicleList.filterLabel.internalName')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.brand"
              type="text"
              clearable
              :label="$t('VehicleList.filterLabel.brand')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.model"
              type="text"
              clearable
              :label="$t('VehicleList.filterLabel.model')"
            ></v-text-field>
          </td>
          <td>
            <v-text-field
              v-model="filter.lpn"
              type="text"
              clearable
              :label="$t('VehicleList.filterLabel.lpn')"
            ></v-text-field>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </template>
      <template v-slot:[`body.prepend`] v-else>
        <v-row dense>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.internalName"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleList.filterLabel.internalName')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.brand"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleList.filterLabel.brand')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.model"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleList.filterLabel.model')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="filter.lpn"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('VehicleList.filterLabel.lpn')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip small :color="getStatusColor(item.status)" dark label>
          {{ $t("VehicleEnums.Status." + item.status) }}
        </v-chip>
      </template>

      <template v-slot:[`item.color`]="{ item }">
        <v-chip
          small
          :color="colorRgbEnum[item.color]"
          :class="
            ColorDarkContrastEnum[item.color] == true
              ? 'white--text'
              : 'black--text'
          "
          label
        >
          {{ $t("vehicleColor_short." + item.color) }}
        </v-chip>
      </template>

      <template v-slot:[`item.active`]="{ item }">
        <v-simple-checkbox v-model="item.active" disabled></v-simple-checkbox>
      </template>

      <template v-slot:[`item.type`]="{ item }">
        <v-chip small :color="getTypeColor(item.type)" dark label>
          {{ $t("VehicleEnums.Type." + item.type) }}
        </v-chip>
      </template>

      <template v-slot:[`item.mileages`]="{ item }">
        {{ item.mileages }} km
      </template>

      <template v-slot:[`item.lastMaintenanceAt`]="{ item }">
        <span>{{
          $options.filters.formatDateShort(item.lastMaintenanceAt)
        }}</span>
      </template>

      <template v-slot:no-data>
        <v-btn color="error" @click="getAll()"> {{ $t("btnReload") }}</v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="detail(item.id)">mdi-eye</v-icon>
        <v-icon small @click="showUpdateLastVehicleMaintenanceDialog(item.id)"
          >mdi-bell-cog-outline</v-icon
        >
      </template>
    </v-data-table>

    <v-dialog
      v-model="createDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{
          $t("VehicleList.dialogCreateTitle")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="formCreate">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('VehicleList.internalName')"
                    filled
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createItem.internalName"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <v-select
                    :label="$t('VehicleList.brand')"
                    filled
                    v-model="createItem.brand"
                    :items="Object.keys(vehicleBrandEnum)"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    :label="$t('VehicleList.model')"
                    filled
                    v-model="createItem.model"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12">
                  <!-- :items="Object.keys(typeEnum)" -->
                  <v-select
                    :label="$t('VehicleList.type')"
                    filled
                    v-model="createItem.type"
                    :items="$t('VehicleEnums.TypeArray')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>

                <v-col cols="6">
                  <v-text-field
                    :label="$t('VehicleList.lpn')"
                    filled
                    v-model="createItem.lpn"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="6">
                  <!-- :items="Object.keys(colorEnum)" -->
                  <v-select
                    :label="$t('VehicleList.color')"
                    filled
                    :items="$t('vehicleColorArray')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createItem.color"
                    required
                  >
                  </v-select>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="createDialog = false">{{
              $t("btnClose")
            }}</v-btn>
            <v-btn color="success" text @click.stop="submitCreateDialog()">{{
              $t("btnCreate")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <!-- Dialog na aktualizaciu maintenance casu a kilometrov vozidla -->
    <v-dialog v-model="updateLastVehicleMaintenanceDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">{{
            $t("VehicleList.dialogUpdateLastMaintenanceTitle")
          }}</span>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="updateMaintenanceData.id"
                  type="text"
                  :label="$t('VehicleList.vehicleId')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItemMaintenance.brand"
                  type="text"
                  :label="$t('VehicleList.brand')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItemMaintenance.model"
                  type="text"
                  :label="$t('VehicleList.model')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItemMaintenance.lpn"
                  type="text"
                  :label="$t('VehicleList.lpn')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6">
                <v-text-field
                  v-model="selectedItemMaintenance.mileages"
                  type="text"
                  :label="$t('VehicleList.mileages')"
                  readonly
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>

            <v-divider class="pt-4 pb-2 mt-4 mb-2"></v-divider>
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  @click="setLastMaintenanceAtLast(updateMaintenanceData.id)"
                >
                  {{ $t("VehicleList.btnSetLastMaintenanceAtFromVehicle") }}
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-btn
                  color="primary"
                  @click="setLastMaintenanceAtNow(updateMaintenanceData.id)"
                >
                  {{ $t("VehicleList.btnSetLastMaintenanceAtToNow") }}
                </v-btn>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <my-datepicker
                  v-model="updateMaintenanceData.lastMaintenanceAt"
                  :label="$t('VehicleList.lastMaintenanceAt')"
                  dense
                  :hideDetails="true"
                >
                </my-datepicker>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="updateMaintenanceData.lastMaintenanceMileage"
                  type="number"
                  :label="$t('VehicleList.lastMaintenanceMileage')"
                  dense
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="updateLastVehicleMaintenanceDialog = false"
          >
            {{ $t("btnClose") }}
          </v-btn>
          <v-btn
            color="success"
            text
            @click.stop="submitUpdateLastVehicleMaintenanceDialog()"
            >{{ $t("btnUpdate") }}</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import {
  Type,
  Status,
  Color,
  ColorRgb,
  ColorDarkContrast,
  VehicleBrand,
} from "../enums/VehicleEnum";

export default {
  data() {
    return {
      //enums
      typeEnum: Type,
      vehicleBrandEnum: VehicleBrand,
      colorEnum: Color,
      colorRgbEnum: ColorRgb,
      ColorDarkContrastEnum: ColorDarkContrast,
      statusEnum: Status,

      //Prepinanie zobrazenia tabulky pri malom rozliseni z dat v riadkoch na standardnu stlpcovu tabulku
      mobileBreakpointSwitch: false,
      mobileBreakpointTable: 600,

      rowsPerPage: [10, 20, 30, 40, 50],
      gridHeaders: [
        {
          text: this.$t("VehicleList.tableHeaders.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("VehicleList.tableHeaders.internalName"),
          value: "internalName",
          sortable: true,
          filter: (value) => {
            if (!this.filter.internalName) {
              return value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.internalName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("VehicleList.tableHeaders.brand"),
          value: "brand",
          sortable: true,
          filter: (value) => {
            if (!this.filter.brand) {
              return value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.brand.toLowerCase());
            }
          },
        },
        {
          text: this.$t("VehicleList.tableHeaders.model"),
          value: "model",
          sortable: true,
          filter: (value) => {
            if (!this.filter.model) {
              return value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.model.toLowerCase());
            }
          },
        },
        {
          text: this.$t("VehicleList.tableHeaders.lpn"),
          value: "lpn",
          sortable: true,
          filter: (value) => {
            if (!this.filter.lpn) {
              return value;
            } else {
              return (value + "")
                .toLowerCase()
                .includes(this.filter.lpn.toLowerCase());
            }
          },
        },
        { text: this.$t("VehicleList.tableHeaders.type"), value: "type" },
        { text: this.$t("VehicleList.tableHeaders.color"), value: "color" },
        {
          text: this.$t("VehicleList.tableHeaders.mileages"),
          value: "mileages",
        },
        { text: this.$t("VehicleList.tableHeaders.status"), value: "status" },
        { text: this.$t("VehicleList.tableHeaders.active"), value: "active" },
        { text: this.$t("VehicleList.tableHeaders.phoneExt"), value: "phoneExt" },
        { text: this.$t("VehicleList.tableHeaders.phone"), value: "phone" },
        {
          text: this.$t("VehicleList.tableHeaders.lastMaintenanceAt"),
          value: "lastMaintenanceAt",
        },
        {
          text: this.$t("VehicleList.tableHeaders.lastMaintenanceMileage"),
          value: "lastMaintenanceMileage",
        },
        {
          text: this.$t("VehicleList.tableHeaders.maintenanceIntervalDay"),
          value: "maintenanceIntervalDay",
        },
        {
          text: this.$t("VehicleList.tableHeaders.maintenanceIntervalMileage"),
          value: "maintenanceIntervalMileage",
        },
        {
          text: this.$t("VehicleList.tableHeaders.maintenanceNotificationDay"),
          value: "maintenanceNotificationDay",
        },
        {
          text: this.$t(
            "VehicleList.tableHeaders.maintenanceNotificationMileage"
          ),
          value: "maintenanceNotificationMileage",
        },
      ],
      gridItems: [],

      filter: {
        search: "",
        internalName: "",
        brand: "",
        model: "",
        lpn: "",
      },

      loadingData: false,

      createDefaultItem: {
        internalName: "",
        type: "",
        lpn: "",
        color: "",
        brand: "",
        model: "",
      },
      createItem: {},

      createDialog: false,

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },

      updateLastVehicleMaintenanceDialog: false,
      updateMaintenanceData: {
        id: null,
        lastMaintenanceAt: null,
        lastMaintenanceMileage: null,
      },
      selectedItemMaintenance: {
        vehicleId: "",
        brand: "",
        model: "",
        internalName: "",
        lpn: "",
        status: "",
        active: "",
        mileages: "",
      },
    };
  },
  mounted() {
    this.getAll();
  },
  watch: {
    mobileBreakpointSwitch: function () {
      //ak sa zmeni mobileBreakpointSwitch, nadefinovat hodnotu pre mobileBreakpoint
      if (this.mobileBreakpointSwitch === true) {
        this.mobileBreakpointTable = 0;
      } else {
        this.mobileBreakpointTable = 600;
      }
      //console.log("mobileBreakpointTable", this.mobileBreakpointTable);
      this.setCookie("mobileBreakpointSwitch", this.mobileBreakpointSwitch, 30);
    },
  },
  methods: {
    getAll() {
      this.loadingData = true;
      axios
        .get(process.env.VUE_APP_API_BASE_URL + `/dispatching/vehicle`)
        .then((response) => {
          this.loadingData = false;
          this.gridItems = response.data;
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },
    detail(id) {
      this.$router.push({ name: "vehicle-detail", params: { id: id } });
    },
    openCreateDialog() {
      this.createItem = Object.assign({}, this.createDefaultItem);
      this.createDialog = true;
    },
    submitCreateDialog() {
      const isValid = this.$refs.formCreate.validate();
      if (isValid) {
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/vehicle`,
            this.createItem
          )
          .then((response) => {
            this.createDialog = false;
            this.detail(response.data.id);
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgCreateError");
            this.snackbar.color = "error";
          });
      }
    },
    showUpdateLastVehicleMaintenanceDialog(id) {
      var selectedItem;
      selectedItem = Object.assign(
        {},
        this.gridItems.filter(function (elem) {
          if (elem.id == id) return elem;
        })[0]
      );
      if (selectedItem) {
        this.selectedItemMaintenance = selectedItem;
        this.updateMaintenanceData.id = selectedItem.id;
        this.updateMaintenanceData.lastMaintenanceAt =
          selectedItem.lastMaintenanceAt;
        this.updateMaintenanceData.lastMaintenanceMileage =
          selectedItem.lastMaintenanceMileage;
        this.updateLastVehicleMaintenanceDialog = true;
      }
    },
    submitUpdateLastVehicleMaintenanceDialog() {
      axios
        .put(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/vehicle/maintenance`,
          this.updateMaintenanceData
        )
        .then((response) => {
          this.updateLastVehicleMaintenanceDialog = false;
          this.getAll();
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgUpdateError");
          this.snackbar.color = "error";
        });
    },
    setLastMaintenanceAtLast(id) {
      var selectedItem;
      selectedItem = Object.assign(
        {},
        this.gridItems.filter(function (elem) {
          if (elem.id == id) return elem;
        })[0]
      );
      if (selectedItem) {
        this.updateMaintenanceData.lastMaintenanceAt =
          selectedItem.lastMaintenanceAt;
        this.updateMaintenanceData.lastMaintenanceMileage =
          selectedItem.lastMaintenanceMileage;
      }
    },
    setLastMaintenanceAtNow(id) {
      var selectedItem;
      selectedItem = Object.assign(
        {},
        this.gridItems.filter(function (elem) {
          if (elem.id == id) return elem;
        })[0]
      );
      if (selectedItem) {
        this.updateMaintenanceData.lastMaintenanceAt = new Date();
        this.updateMaintenanceData.lastMaintenanceAt.setSeconds(0);
        this.updateMaintenanceData.lastMaintenanceAt.setMilliseconds(0);
        this.updateMaintenanceData.lastMaintenanceMileage =
          selectedItem.mileages;
      }
    },

    getStatusColor(status) {
      switch (status) {
        case this.statusEnum.ACTIVE:
          return "green";
        default:
          return "black";
      }
    },
    getTypeColor(type) {
      switch (type) {
        case Type.SEDAN:
          return "green";
        case Type.COMBI:
          return "blue";
        case Type.CABRIO:
          return "yellow";
        case Type.COUPE:
          return "purple";
        case Type.HATCHBACK:
          return "red";
        case Type.VAN:
          return "lime";
        case Type.MICROBUS:
          return "cyan";
        case Type.BUS:
          return "teal";
        case Type.VAN_CARGO:
          return "pink";
        case Type.PICKUP:
          return "brown";
        case Type.SUV:
          return "orange";
        default:
          return "black";
      }
    },
  },
};
</script>
