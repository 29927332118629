export const Status = Object.freeze({
  DISABLED: "DISABLED", // Neaktívny (0)
  ACTIVE: "ACTIVE", // Aktívny (1)
  BLOCKED: "BLOCKED", // Blokovaný, dočasne zablokovaný (2)
  ARCHIVED: "ARCHIVED", // Archivovaný (3)
});

export const StatusAccountColor = Object.freeze({
  DISABLED: "red",
  ACTIVE: "green",
  BLOCKED: "purple",
  ARCHIVED: "grey",
});

export const StatusClientColor = Object.freeze({
  DISABLED: "red",
  ACTIVE: "green",
  PENDING: "orange",
  BLOCKED: "purple",
  ARCHIVED: "grey",
});