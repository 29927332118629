<template>
  <span>
    <v-btn
      small
      class="my-2 ml-2"
      color="info"
      @click.stop="importDataDialog = true"
    >
      <v-icon left dark> mdi-import </v-icon>
      {{ $t("btnImportData") }}
    </v-btn>
    <v-dialog
      v-model="importDataDialog"
      transition="dialog-top-transition"
      :max-width="dialogWidth"
    >
      <v-card>
        <v-toolbar color="secondary" dark dense flat>
          <v-toolbar-title>Import</v-toolbar-title></v-toolbar
        >
        <v-divider></v-divider>
        <v-form ref="editSelectorForm">
          <v-card-text>
            <v-container>
              <v-stepper v-model="currentStep">
                <v-stepper-header>
                  <v-stepper-step :complete="currentStep > 1" step="1"
                    >Vybrať súbor</v-stepper-step
                  >
                  <v-divider></v-divider>
                  <v-stepper-step :complete="currentStep > 2" step="2"
                    >Mapovať stĺpce</v-stepper-step
                  >
                  <v-divider></v-divider>
                  <v-stepper-step step="3">Importovať</v-stepper-step>
                </v-stepper-header>

                <v-stepper-items>
                  <v-stepper-content step="1">
                    <v-file-input
                      v-model="file"
                      :rules="[validateFile]"
                      label="Vybrať CSV súbor"
                      accept=".csv"
                      @change="parseFile"
                    ></v-file-input>
                    <v-select
                      v-model="delimiter"
                      :items="[';', ',']"
                      label="Oddeľovač"
                      outlined
                      dense
                    ></v-select>
                    <v-btn color="primary" @click="nextStep" :disabled="!file"
                      >Ďalej</v-btn
                    >
                  </v-stepper-content>

                  <v-stepper-content step="2">
                    <v-simple-table>
                      <template v-slot:default>
                        <thead>
                          <tr>
                            <th>CSV stĺpec</th>
                            <th>Požadovaný stĺpec</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(col, index) in parsedColumns"
                            :key="index"
                          >
                            <td>{{ col }}</td>
                            <td>
                              <v-select
                                v-model="columnMapping[index]"
                                :items="columnOptions"
                                outlined
                                dense
                              ></v-select>
                            </td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                    <v-btn color="primary" @click="previousStep">Späť</v-btn>
                    <v-btn color="primary" @click="importData"
                      >Importovať</v-btn
                    >
                  </v-stepper-content>

                  <v-stepper-content step="3">
                    <p>Importovanie údajov dokončené.</p>
                    <v-btn color="primary" @click="reset">Resetovať</v-btn>
                  </v-stepper-content>
                </v-stepper-items>
              </v-stepper>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
export default {
  props: {
    columnOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      importDataDialog: false,
      dialogWidth: 600,
      currentStep: 1,
      file: null,
      parsedColumns: [],
      parsedData: [],
      columnMapping: {},
      delimiter: ";",
    };
  },
  methods: {
    validateFile(file) {
      return file.type === "text/csv" || "Vyberte súbor vo formáte CSV";
    },
    parseFile(file) {
      const reader = new FileReader();
      reader.onload = () => {
        const [columns, ...data] = reader.result
          .split("\n")
          .filter((line) => line.trim())
          .map((line) => line.split(this.delimiter));

        this.parsedColumns = columns;
        this.parsedData = data;
      };
      reader.readAsText(file);
    },
    nextStep() {
      this.currentStep++;
    },
    previousStep() {
      this.currentStep--;
    },
    importData() {
      const outputData = this.parsedData.map((row) =>
        row.reduce((acc, val, index) => {
          if (this.columnMapping[index]) {
            acc[this.columnMapping[index]] = val;
          }
          return acc;
        }, {})
      );
      this.$emit("imported", outputData);
      this.nextStep();
    },
    reset() {
      this.currentStep = 1;
      this.file = null;
      this.parsedColumns = [];
      this.parsedData = [];
      this.columnMapping = {};
    },
  },
};
</script>