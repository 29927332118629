<template>
  <div>
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("AccountList.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>mdi-store</v-icon>
    </v-toolbar>
    <v-row justify="end">
      <v-spacer></v-spacer>
      <v-col v-show="$vuetify.breakpoint.smAndDown" cols="12" md="3">
        <v-switch
          v-model="mobileBreakpointSwitch"
          :label="$t('AccountList.mobileBreakpointSwitch_label')"
          hide-details
        ></v-switch
      ></v-col>
    </v-row>
    <v-row
      ><v-col cols="12" md="3">
        <v-btn
          small
          class="my-2"
          color="success"
          @click.stop="openCreateDialog()"
        >
          {{ $t("btnCreate") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-data-table
      :loading="loadingData"
      :headers="gridHeaders"
      :items="gridItems"
      class="elevation-1"
      :items-per-page="50"
      :footer-props="{
        itemsPerPageOptions: [15, 25, 50, 100, -1],
        showFirstLastPage: true,
      }"
      :mobile-breakpoint="mobileBreakpointTable"
    >
      <template
        v-slot:[`body.prepend`]
        v-if="$vuetify.breakpoint.width > mobileBreakpointTable"
      >
        <tr>
          <td :hidden="actionsHidden"><v-icon>mdi-filter</v-icon></td>
          <td :hidden="accountIdHidden">
            <v-text-field
              v-model="filter.accountId"
              type="text"
              clearable
              :label="$t('AccountList.filterLabel.accountId')"
            ></v-text-field>
          </td>
          <td :hidden="statusHidden">
            <v-select
              :label="$t('AccountList.filterLabel.status')"
              clearable
              v-model="filter.status"
              :items="Object.values($t('AccountEnums.StatusArray'))"
              dense
              hide-details
            ></v-select>
          </td>
          <td :hidden="accountStatusHidden">
            <v-select
              :label="$t('AccountList.filterLabel.accountStatus')"
              clearable
              v-model="filter.accountStatus"
              :items="Object.values($t('AccountEnums.StatusArray'))"
              dense
              hide-details
            ></v-select>
          </td>
          <td :hidden="validToHidden"></td>
          <td :hidden="nameHidden">
            <v-text-field
              v-model="filter.name"
              type="text"
              clearable
              :label="$t('AccountList.filterLabel.name')"
            ></v-text-field>
          </td>
          <td :hidden="companyIdNumberHidden">
            <v-text-field
              v-model="filter.companyIdNumber"
              type="text"
              clearable
              :label="$t('AccountList.filterLabel.companyIdNumber')"
            ></v-text-field>
          </td>
          <td :hidden="countryHidden">
            <v-text-field
              v-model="filter.country"
              type="text"
              clearable
              :label="$t('AccountList.filterLabel.country')"
            ></v-text-field>
          </td>
          <td :hidden="companyNameHidden"></td>
          <td :hidden="emailHidden"></td>
        </tr>
      </template>
      <template v-slot:[`body.prepend`] v-else>
        <v-row dense>
          <v-col cols="12" sm="6" :hidden="actionsHidden"></v-col>
          <v-col cols="12" sm="6" :hidden="accountIdHidden">
            <v-text-field
              v-model="filter.id"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('AccountList.filterLabel.accountId')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="statusHidden">
            <v-select
              :label="$t('AccountList.filterLabel.status')"
              clearable
              prepend-inner-icon="mdi-magnify"
              v-model="filter.status"
              :items="Object.values($t('AccountEnums.StatusArray'))"
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" :hidden="accountStatusHidden">
            <v-select
              :label="$t('AccountList.filterLabel.accountStatus')"
              clearable
              prepend-inner-icon="mdi-magnify"
              v-model="filter.accountStatus"
              :items="Object.values($t('AccountEnums.StatusArray'))"
              dense
              hide-details
            ></v-select>
          </v-col>
          <v-col cols="12" sm="6" :hidden="nameHidden">
            <v-text-field
              v-model="filter.name"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('AccountList.filterLabel.name')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="companyIdNumberHidden">
            <v-text-field
              v-model="filter.companyIdNumber"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('AccountList.filterLabel.companyIdNumber')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" :hidden="countryHidden">
            <v-text-field
              v-model="filter.country"
              type="text"
              clearable
              prepend-inner-icon="mdi-magnify"
              :label="$t('AccountList.filterLabel.country')"
              dense
              hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </template>

      <template v-slot:[`item.status`]="{ item }">
        <v-chip small :color="getStatusColor(item.status)" dark label>
          {{ $t("AccountEnums.Status." + item.status) }}
        </v-chip>
      </template>

      <template v-slot:[`item.accountStatus`]="{ item }">
        <v-chip small :color="getStatusColor(item.accountStatus)" dark label>
          {{ $t("AccountEnums.Status." + item.accountStatus) }}
        </v-chip>
      </template>

      <template v-slot:[`item.validTo`]="{ item }"
        ><!-- Ak je validTo skorsi ako aktualny čas, zobrazit tucnym pismom -->
        <span
          v-if="item.validTo < new Date().toISOString().slice(0, 10)"
          class="error--text"
          ><b>{{
            item.validTo ? $options.filters.formatDateLong(item.validTo) : "-"
          }}</b></span
        >
        <span v-else>{{
          item.validTo ? $options.filters.formatDateLong(item.validTo) : "-"
        }}</span>
      </template>

      <template v-slot:no-data>
        <v-btn color="error" @click="getAll()">{{ $t("btnReload") }}</v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small @click="detail(item.accountId)">fa-eye</v-icon>
      </template>
    </v-data-table>

    <v-dialog
      v-model="createDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{ $t("AccountList.dialogTitle") }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="formCreate">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    :label="$t('AccountList.name')"
                    filled
                    dense
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createItem.name"
                    :hint="$t('AccountList.name_hint')"
                    required
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6" class="py-0">
                  <v-select
                    :label="$t('AccountList.country')"
                    filled
                    dense
                    v-model="createItem.country"
                    :items="$t('CountryCodeList')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    :hint="$t('AccountList.country_hint')"
                    required
                  ></v-select>
                </v-col>

                <!--<v-col cols="12" md="6" class="py-0">
                  <v-select
                    :label="$t('AccountList.status')"
                    filled
                    dense
                    v-model="createItem.status"
                    :items="$t('AccountEnums.StatusArray')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    :disabled="!displayOnlyForAdmin"
                    required
                  ></v-select>
                </v-col>-->

                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    :label="$t('AccountList.email')"
                    filled
                    dense
                    v-model="createItem.email"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    :label="$t('AccountList.companyIdNumber')"
                    filled
                    dense
                    v-model="createItem.companyIdNumber"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    :hint="$t('AccountList.companyIdNumber_hint')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    :label="$t('AccountList.companyTaxId')"
                    filled
                    dense
                    v-model="createItem.companyTaxId"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-select
                    :label="$t('AccountList.companyVatPayer')"
                    filled
                    dense
                    v-model="createItem.companyVatPayer"
                    :items="$t('AccountEnums.CompanyVatPayer')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <v-text-field
                    :label="$t('AccountList.companyVatId')"
                    filled
                    dense
                    v-model="createItem.companyVatId"
                    :required="createItem.companyVatPayer == 'VAT'"
                    :disabled="createItem.companyVatPayer != 'VAT'"
                    :rules="
                      createItem.companyVatPayer == 'VAT'
                        ? [(v) => !!v || $t('msgRequiredField')]
                        : []
                    "
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" class="py-0">
                  <!-- <v-btn
                    color="primary"
                    @click="getPublicAccountDataFromOrsr()"
                    :disabled="!createItem.companyIdNumber"
                    v-show="displayOnlyForAdmin"
                  >
                    {{ $t("AccountList.btnOrsr") }}
                  </v-btn> -->
                  <v-btn
                    color="primary"
                    @click="setNextNoCompanyIdNumberChanged()"
                  >
                    {{ $t("AccountList.btnNextNoCIN") }}
                  </v-btn>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    :label="$t('AccountList.validTo')"
                    filled
                    dense
                    v-model="createItem.validTo"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                    disabled
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    :label="$t('AccountList.companyName')"
                    filled
                    dense
                    v-model="createItem.companyName"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    :hint="$t('AccountList.companyName_hint')"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    :label="$t('AccountList.companyAddress1')"
                    filled
                    dense
                    v-model="createItem.companyAddress1"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    :label="$t('AccountList.companyAddress2')"
                    filled
                    dense
                    v-model="createItem.companyAddress2"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" class="py-0">
                  <v-text-field
                    :label="$t('AccountList.companyAddress3')"
                    filled
                    dense
                    v-model="createItem.companyAddress3"
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="createDialog = false">{{
              $t("btnClose")
            }}</v-btn>
            <v-btn color="success" text @click.stop="submitCreateDialog()">{{
              $t("btnCreate")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import { Status, StatusAccountColor } from "../enums/AccountEnum";
import { Country } from "../enums/CountryEnum";

export default {
  data() {
    return {
      moment: moment,
      displayOnlyForAdmin: false,
      noCompanyIdNumber: false,
      //enums
      Status,
      countryEnum: Country,
      StatusAccountColor,
      //Prepinanie zobrazenia tabulky pri malom rozliseni z dat v riadkoch na standardnu stlpcovu tabulku
      mobileBreakpointSwitch: false,
      mobileBreakpointTable: 600,

      filter: {
        search: "",
        accountId: "",
        status: "",
        accountStatus: "",
        validTo: "",
        name: "",
        companyIdNumber: "",
        country: "",
        companyName: "",
        email: "",
      },

      actionsHidden: false,
      accountIdHidden: false,
      statusHidden: false,
      accountStatusHidden: false,
      validToHidden: false,
      nameHidden: false,
      companyIdNumberHidden: false,
      countryHidden: false,
      companyNameHidden: false,
      emailHidden: false,

      gridHeaders: [
        {
          text: this.$t("AccountList.tableHeaders.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("AccountList.tableHeaders.accountId"),
          value: "accountId",
          filter: (value) => {
            if (!this.filter.accountId) return true;
            if (value) return value === parseInt(this.filter.accountId);
          },
        },
        {
          text: this.$t("AccountList.tableHeaders.status"),
          value: "status",
          filter: (value) => {
            if (!this.filter.status) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.status.toLowerCase());
            }
          },
        },
        {
          text: this.$t("AccountList.tableHeaders.accountStatus"),
          value: "accountStatus",
          filter: (value) => {
            if (!this.filter.accountStatus) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.accountStatus.toLowerCase());
            }
          },
        },
        {
          text: this.$t("AccountList.tableHeaders.validTo"),
          value: "validTo",
        },
        {
          text: this.$t("AccountList.tableHeaders.name"),
          value: "name",
          filter: (value) => {
            if (!this.filter.name) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.name.toLowerCase());
            }
          },
        },
        {
          text: this.$t("AccountList.tableHeaders.companyIdNumber"),
          value: "companyIdNumber",
          filter: (value) => {
            if (!this.filter.companyIdNumber) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.companyIdNumber.toLowerCase());
            }
          },
        },
        {
          text: this.$t("AccountList.tableHeaders.country"),
          value: "country",
          filter: (value) => {
            if (!this.filter.country) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.country.toLowerCase());
            }
          },
        },
        {
          text: this.$t("AccountList.tableHeaders.companyName"),
          value: "companyName",
          filter: (value) => {
            if (!this.filter.companyName) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.companyName.toLowerCase());
            }
          },
        },
        {
          text: this.$t("AccountList.tableHeaders.email"),
          value: "email",
          filter: (value) => {
            if (!this.filter.email) {
              return true;
            } else {
              return value
                .toLowerCase()
                .includes(this.filter.email.toLowerCase());
            }
          },
        },
      ],
      gridItems: [],

      loadingData: false,
      taxiserviceId: 0,

      createDefaultItem: {
        //id	:null,
        name: "",
        status: "ACTIVE",
        companyName: "",
        companyIdNumber: "",
        companyTaxId: "",
        companyVatId: "",
        companyVatPayer: "",
        country: "",
        email: "",
        companyAddress1: "",
        companyAddress2: "",
        companyAddress3: "",
        validFrom: "",
        validTo: "2099-12-31T23:59:59.007416Z",
      },
      createItem: {},

      createDialog: false,

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },
  mounted() {
    this.taxiserviceId = localStorage.getItem("selectedTaxiserviceId");
    var mobileBreakpointSwitch = this.getCookie("mobileBreakpointSwitch");
    if (mobileBreakpointSwitch && mobileBreakpointSwitch != null) {
      this.mobileBreakpointSwitch =
        mobileBreakpointSwitch.toLowerCase() == "true";
    } else {
      this.mobileBreakpointSwitch = false;
    }
    this.getUserRole();
    this.getAll();
  },
  watch: {
    mobileBreakpointSwitch: function () {
      //ak sa zmeni mobileBreakpointSwitch, nadefinovat hodnotu pre mobileBreakpoint
      if (this.mobileBreakpointSwitch === true) {
        this.mobileBreakpointTable = 0;
      } else {
        this.mobileBreakpointTable = 600;
      }
      //console.log("mobileBreakpointTable", this.mobileBreakpointTable);
      this.setCookie("mobileBreakpointSwitch", this.mobileBreakpointSwitch, 30);
    },
  },
  methods: {
    getUserRole() {
      let enabledRoles = ["ADMIN", "SUPPORT"];
      let userRoles = this.$store.state.user.roleList;
      this.displayOnlyForAdmin =
        userRoles.some((a) => (enabledRoles.includes(a) ? true : false)) ===
        true;
    },
    getAll() {
      this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL + `/dispatching/taxiservice-account`
        )
        .then((response) => {
          this.loadingData = false;
          this.gridItems = response.data;
          //search gridItems for companyIdNumber with "x" on the beginning and set to this.noCompanyIdNumber with highest number after "x"
          let prefix = "x" + this.taxiserviceId + "q";
          let noCompanyIdNumber = this.gridItems
            .filter((item) => item.companyIdNumber.startsWith(prefix))
            .map((item) => item.companyIdNumber.replace(prefix, ""))
            .sort((a, b) => b - a)[0];
          this.noCompanyIdNumber = noCompanyIdNumber ? noCompanyIdNumber : 0;
        })
        .catch((e) => {
          this.loadingData = false;
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },
    detail(id) {
      this.$router.push({ name: "account-detail", params: { id: id } });
    },
    openCreateDialog() {
      this.createItem = Object.assign({}, this.createDefaultItem);
      this.createDialog = true;
    },
    submitCreateDialog() {
      //check if gridItems has same companyIdNumber and country is the same
      let sameCompanyIdNumber = this.gridItems.find(
        (item) =>
          item.companyIdNumber == this.createItem.companyIdNumber &&
          item.country == this.createItem.country
      );
      if (sameCompanyIdNumber) {
        this.snackbar.show = "true";
        this.snackbar.message = this.$t("msgAccountAlreadyExists");
        this.snackbar.color = "error";
        return;
      }
      console.log(sameCompanyIdNumber);

      const isValid = this.$refs.formCreate.validate();
      if (isValid) {
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/taxiservice-account`,
            this.createItem
          )
          .then((response) => {
            if ((response.data.status = "OK")) {
              this.snackbar.show = "true";
              this.snackbar.message = this.$t("msgCreateSuccess");
              this.snackbar.color = "success";
              this.getAll();
            } else {
              this.snackbar.show = "true";
              this.snackbar.message = this.$t("msgCreateError");
              this.snackbar.color = "error";
            }
            this.createDialog = false;
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgCreateError");
            this.snackbar.color = "error";
          });
      }
    },
    setNextNoCompanyIdNumberChanged() {
      //set companyIdNumber to "x" + (this.noCompanyIdNumber + 1) with 6 digits
      //convert this.noCompanyIdNumber to integer and add 1
      var noCompanyIdNumber = parseInt(this.noCompanyIdNumber) + 1;
      let prefix = "x" + this.taxiserviceId + "q";
      this.createItem.companyIdNumber =
        prefix + noCompanyIdNumber.toString().padStart(6, "0");
      this.createItem.companyTaxId = "0000000";
      this.createItem.companyVatId = "0000000";
      this.createItem.companyVatPayer = "NO_VAT";
      this.createItem.country = "SK";
      this.createItem.email = "-";
      this.createItem.companyName = this.createItem.name;
      this.createItem.companyAddress1 = "-";
      this.createItem.companyAddress2 = "-";
      this.createItem.companyAddress3 = "-";
    },

    getPublicAccountDataFromOrsr() {
      //Integrácia na slovensko.digital - https://ekosystem.slovensko.digital/sluzby/autoform/integracny-manual
      //https://www.orsr.sk/api/orsr-api-v2.html
      var paramters = {
        private_access_token:
          "e9d8f2c17eec2df2cd6a8ef7b1a885200bf7e4ca3935d9a2736c52550539acf34e66579b05bb5e9a",
        q: "cin:" + this.createItem.companyIdNumber,
        limit: 1,
        filter: "active",
      };
      axios
        .get(
          `https://autoform.ekosystem.slovensko.digital/api/corporate_bodies/search`,
          { params: paramters, headers: { "Access-Control-Allow-Origin": "*" } }
        )
        .then((response) => {
          //get first item from response
          var responseData = response.data;
          this.createItem.companyName = responseData["0"]["name"];
          this.createItem.companyAddress1 =
            responseData["0"]["formatted_street"];
          this.createItem.companyAddress2 =
            responseData["0"]["postal_code"] +
            " " +
            responseData["0"]["municipality"];
          this.createItem.companyAddress3 = responseData["0"]["country"];
          this.createItem.companyTaxId = responseData["0"]["tin"];
          this.createItem.companyVatId = responseData["0"]["vatin"];
          if (responseData["0"]["vatin"]) {
            if (responseData["0"]["vatin"] != null) {
              this.createItem.companyVatPayer = "VAT";
            } else {
              this.createItem.companyVatPayer = "NO_VAT";
            }
          }
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },

    getStatusColor(status) {
      return this.StatusAccountColor[status];
    },
    //Vytvorenie a aktualizácia cookie
    setCookie(nameC, valueC, daysToLive) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      // Encode value in order to escape semicolons, commas, and whitespace
      var cookie = nameC + "=" + encodeURIComponent(valueC);
      if (typeof daysToLive === "number") {
        /* Sets the max-age attribute so that the cookie expires after the specified number of days */
        cookie +=
          "; max-age=" + daysToLive * 24 * 60 * 60 + "; path=/;SameSite=strict"; //; domain=taxximo.com
        document.cookie = cookie;
        //console.log("setCookie: " + cookie);
      }
    },

    //Nacitanie hodnoty z cookie
    getCookie(nameC) {
      //nameC = this.taxiserviceId + "_" + this.dispatcherId + "_" + nameC;
      //console.log("getCookie - name: " + nameC);
      // Split cookie string and get all individual name=value pairs in an array
      var cookieArr = document.cookie.split(";");
      // Loop through the array elements
      for (var i = 0; i < cookieArr.length; i++) {
        var cookiePair = cookieArr[i].split("=");
        /* Removing whitespace at the beginning of the cookie name and compare it with the given string */
        if (nameC == cookiePair[0].trim()) {
          // Decode the cookie value and return
          //console.log("getCookieValue decode: " + decodeURIComponent(cookiePair[1]));
          return decodeURIComponent(cookiePair[1]);
        }
      }
      // Return null if not found
      return null;
    },
  },
};
</script>
