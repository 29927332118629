<template>
  <div v-if="item">
    <v-toolbar color="secondary" dark flat>
      <v-toolbar-title>{{ $t("Vehicle.page_title") }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-icon x-large>fa-car</v-icon>
      <template v-slot:extension>
        <v-tabs v-model="tabs" align-with-title>
          <v-tabs-slider color="primary"></v-tabs-slider>
          <v-tab key="tab1">{{ $t("Vehicle.tabInfo") }}</v-tab>
          <v-tab key="tab2">{{ $t("Vehicle.tabFilter") }}</v-tab>
          <v-tab key="tab3">{{ $t("Vehicle.tabPhoto") }}</v-tab>
        </v-tabs>
      </template>
    </v-toolbar>

    <v-tabs-items v-model="tabs">
      <!-- TAB1 -->
      <v-tab-item>
        <v-card flat>
          <v-form ref="form">
            <div class="mb-4 mt-4">
              <v-btn
                color="info"
                v-show="!editMode"
                class="mr-4"
                @click="enableEditMode('true')"
                >{{ $t("btnEdit") }}</v-btn
              >
              <v-btn
                class="mr-4"
                color="error"
                v-show="editMode"
                @click="reset()"
                >{{ $t("btnCancel") }}</v-btn
              >
              <v-btn color="success" v-show="editMode" @click="submitForm()">{{
                $t("btnSave")
              }}</v-btn>
            </div>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.internalName"
                  :rules="[
                    (v) => !!v || $t('msgRequiredField'),
                    (v) =>
                      (v && v.length <= 3) || $t('Vehicle.msgInternalNameRule'),
                  ]"
                  :counter="3"
                  :hint="$t('Vehicle.mgsInternalNameHint')"
                  :label="$t('Vehicle.internalName')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.color"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Vehicle.color')"
                  :disabled="!editMode"
                  required
                  :items="$t('vehicleColorArray')"
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <v-select
                  v-model="item.status"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Vehicle.status')"
                  :disabled="!editMode"
                  required
                  :items="$t('VehicleEnums.StatusArray')"
                  filled
                ></v-select>
              </v-col>

              <v-col cols="12" md="3">
                <v-checkbox
                  v-model="item.active"
                  :label="$t('Vehicle.active')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.lpn"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Vehicle.lpn')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                  v-model="item.brand"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Vehicle.brand')"
                  :disabled="!editMode"
                  required
                  filled
                  :items="Object.keys(VehicleBrand)"
                ></v-select>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.model"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Vehicle.model')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-select
                  v-model="item.type"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Vehicle.type')"
                  :disabled="!editMode"
                  required
                  :items="$t('VehicleEnums.TypeArray')"
                  filled
                ></v-select>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phoneExt"
                  :label="$t('Vehicle.phoneExt')"
                  :disabled="!editMode"
                  filled
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  v-model="item.phone"
                  :label="$t('Vehicle.phone')"
                  :disabled="!editMode"
                  filled
                  :placeholder="$t('phone_placeholder')"
                  :hint="$t('phone_hint')"
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.mileages"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Vehicle.mileages')"
                  :disabled="!editMode"
                  suffix="km"
                  required
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  type="text"
                  v-model="item.lastMaintenanceAt"
                  :label="$t('Vehicle.lastMaintenanceAt')"
                  disabled
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.lastMaintenanceMileage"
                  :label="$t('Vehicle.lastMaintenanceMileage')"
                  disabled
                  suffix="km"
                  filled
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.maintenanceIntervalDay"
                  :label="$t('Vehicle.maintenanceIntervalDay')"
                  :disabled="!editMode"
                  required
                  filled
                  hint="Odporúčaná hodnota je 365"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.maintenanceIntervalMileage"
                  :label="$t('Vehicle.maintenanceIntervalMileage')"
                  :disabled="!editMode"
                  suffix="km"
                  required
                  filled
                  hint="Odporúčaná hodnota je 15000"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.maintenanceNotificationDay"
                  :label="$t('Vehicle.maintenanceNotificationDay')"
                  :disabled="!editMode"
                  required
                  filled
                  hint="Odporúčaná hodnota je 30"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.maintenanceNotificationMileage"
                  :label="$t('Vehicle.maintenanceNotificationMileage')"
                  :disabled="!editMode"
                  suffix="km"
                  required
                  filled
                  hint="Odporúčaná hodnota je 1000"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-tab-item>
      <v-tab-item>
        <!-- TAB2 -->
        <v-card flat>
          <v-form ref="form">
            <div class="mb-4 mt-4">
              <v-btn
                color="info"
                v-show="!editMode"
                class="mr-4"
                @click="enableEditMode('true')"
                >{{ $t("btnEdit") }}</v-btn
              >
              <v-btn
                class="mr-4"
                color="error"
                v-show="editMode"
                @click="reset()"
                >{{ $t("btnCancel") }}</v-btn
              >
              <v-btn color="success" v-show="editMode" @click="submitForm()">{{
                $t("btnSave")
              }}</v-btn>
            </div>
            <v-row>
              <v-col cols="12" md="3">
                <v-text-field
                  type="number"
                  v-model="item.maxPerson"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Vehicle.maxPerson')"
                  :disabled="!editMode"
                  required
                  filled
                ></v-text-field>
              </v-col>

              <v-col cols="12" md="3">
                <!-- :items="Object.keys(Smoking)" -->
                <v-select
                  v-model="item.smoking"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Vehicle.smoking')"
                  :disabled="!editMode"
                  :items="$t('vehicleSelect.smoking')"
                  required
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <!-- :items="Object.keys(Airconditioning)" -->
                <v-select
                  v-model="item.airconditioning"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Vehicle.airconditioning')"
                  :disabled="!editMode"
                  :items="$t('vehicleSelect.airconditioning')"
                  required
                  filled
                ></v-select>
              </v-col>
              <!-- </v-row>

            <v-row> -->
              <v-col cols="12" md="3">
                <!-- :items="Object.keys(Eco)" -->
                <v-select
                  v-model="item.eco"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Vehicle.eco')"
                  :disabled="!editMode"
                  :items="$t('vehicleSelect.eco')"
                  required
                  filled
                ></v-select>
              </v-col>

              <v-col cols="12" md="3">
                <!-- :items="Object.keys(Autonomous)" -->
                <v-select
                  v-model="item.autonomous"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Vehicle.autonomous')"
                  :disabled="!editMode"
                  :items="$t('vehicleSelect.autonomous')"
                  required
                  filled
                ></v-select>
              </v-col>
              <!-- </v-row>
            <v-row> -->
              <v-col cols="12" md="3">
                <!-- :items="Object.keys(Animal)" -->
                <v-select
                  v-model="item.animal"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Vehicle.animal')"
                  :disabled="!editMode"
                  :items="$t('vehicleSelect.animal')"
                  required
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <!-- :items="Object.keys(Luggage)" -->
                <v-select
                  v-model="item.luggage"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Vehicle.luggage')"
                  :disabled="!editMode"
                  :items="$t('vehicleSelect.luggage')"
                  required
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <!-- :items="Object.keys(ChildrenChair)" -->
                <v-select
                  v-model="item.childrenChair"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Vehicle.childrenChair')"
                  :disabled="!editMode"
                  :items="$t('vehicleSelect.childrenChair')"
                  required
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <!-- :items="Object.keys(paymentTerminal)" -->
                <v-select
                  v-model="item.paymentTerminal"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Vehicle.paymentTerminal')"
                  :disabled="!editMode"
                  :items="$t('vehicleSelect.paymentTerminal')"
                  required
                  filled
                ></v-select>
              </v-col>
              <v-col cols="12" md="3">
                <!-- :items="Object.keys(SpecialTransport)" -->
                <v-select
                  v-model="item.specialTransport"
                  :rules="[(v) => !!v || $t('msgRequiredField')]"
                  :label="$t('Vehicle.specialTransport')"
                  :disabled="!editMode"
                  :items="$t('vehicleSelect.specialTransport')"
                  required
                  filled
                ></v-select>
              </v-col>
            </v-row>
          </v-form>
        </v-card>
      </v-tab-item>

      <!-- TAB3 -->
      <v-tab-item>
        <v-card flat>
          <v-form ref="form">
            <div class="mb-4 mt-4">
              <v-btn
                color="info"
                v-show="!editMode"
                class="mr-4"
                @click="enableEditMode('true')"
                >{{ $t("btnEdit") }}</v-btn
              >
              <v-btn
                class="mr-4"
                color="error"
                v-show="editMode"
                @click="reset()"
                >{{ $t("btnCancel") }}</v-btn
              >
            </div>
            <div>
              <v-row justify="space-around">
                <v-col cols="12" md="6" class="text-center">
                  <!-- Heading text -->
                  <p>
                    <b>{{ $t("Vehicle.actualPicture") }}</b>
                  </p>
                  <v-spacer></v-spacer>
                  <!-- IMAGE -->
                  <img
                    :src="
                      item.image
                        ? (item.image.startsWith('data:image/')
                            ? ''
                            : 'data:image/png;base64,') + item.image
                        : ''
                    "
                  />
                </v-col>
                <v-col cols="12" md="6" class="text-center">
                  <!-- Heading text -->
                  <p v-show="editMode">
                    <b>{{ $t("Vehicle.actualPicture") }}</b>
                  </p>

                  <v-spacer></v-spacer>
                  <!-- IMAGE -->
                  <my-upload
                    field="app"
                    @crop-success="cropSuccess"
                    @crop-upload-success="cropUploadSuccess"
                    @crop-upload-fail="cropUploadFail"
                    v-model="showPicture"
                    :width="300"
                    :height="300"
                    url=""
                    :params="paramsPicture"
                    :headers="headersPicture"
                    img-format="png"
                    :noCircle="true"
                    :noRotate="false"
                    langType="sk"
                    :langExt="sk"
                  ></my-upload>
                  <img v-show="editMode" :src="imgDataUrl" />
                  <v-spacer></v-spacer>
                  <!-- Tlacidla na vyber a odoslanie obrazka -->
                  <v-btn
                    color="info"
                    :disabled="!editMode"
                    v-show="editMode"
                    class="mr-4"
                    @click="toggleShowPicture"
                    >{{ $t("Vehicle.btnSelectPicture") }}</v-btn
                  >
                  <v-btn
                    color="success"
                    :disabled="!editMode"
                    v-show="editMode"
                    class="mr-4"
                    @click="submitPicture()"
                    >{{ $t("Vehicle.btnSavePicture") }}</v-btn
                  >
                </v-col>
              </v-row>
              <v-row justify="space-around">
                <v-col cols="12" md="6" class="text-left">
                  <p></p>
                </v-col>
                <v-col cols="12" md="6" class="text-center">
                  <p v-show="editMode">
                    <b>{{ $t("Vehicle.examplePicture") }}</b>
                  </p>
                  <img
                    height="200"
                    width="200"
                    :src="require('../assets/media/vehicle_example.png')"
                    v-show="editMode"
                  />
                  <p v-show="editMode">
                    {{ $t("Vehicle.pictureHint") }}
                  </p>
                </v-col>
              </v-row>
            </div>
          </v-form>
        </v-card>
      </v-tab-item>
    </v-tabs-items>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>

  <div v-else>
    <v-btn color="error" @click="getItem()"> {{ $t("btnReload") }}</v-btn>
  </div>
</template>

<script>
import axios from "axios";
import * as moment from "moment/moment";
import myUpload from "vue-image-crop-upload/upload-2.vue";
//https://github.com/dai-siki/vue-image-crop-upload

import {
  ChildrenChair,
  SpecialTransport,
  paymentTerminal,
  Animal,
  Autonomous,
  Luggage,
  Airconditioning,
  Smoking,
  Eco,
  Color,
  Type,
  Status,
  VehicleBrand,
} from "../enums/VehicleEnum";

export default {
  components: {
    "my-upload": myUpload,
  },
  data() {
    return {
      tabs: null,
      //enums
      ChildrenChair,
      SpecialTransport,
      Animal,
      Autonomous,
      Luggage,
      Airconditioning,
      Smoking,
      paymentTerminal,
      Eco,
      Color,
      Type,
      Status,
      VehicleBrand,
      //
      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },

      editMode: false,
      itemId: null,
      item: null,
      cachedItem: null,

      //Crop and upload
      showPicture: false,
      paramsPicture: {
        vehicleId: null,
        //name: "avatar",
        image: null,
        type: "app",
      },
      headersPicture: {
        smail: "*_~",
      },
      imgDataUrl: "", // the datebase64 url of created image

      //Rozsirenie jazyka o SK
      sk: {
        hint: this.$t("Vehicle.uploadPlugin.hint"),
        loading: this.$t("Vehicle.uploadPlugin.loading"),
        noSupported: this.$t("Vehicle.uploadPlugin.noSupported"),
        success: this.$t("Vehicle.uploadPlugin.success"),
        fail: this.$t("Vehicle.uploadPlugin.fail"),
        preview: this.$t("Vehicle.uploadPlugin.preview"),
        btn: {
          off: this.$t("Vehicle.uploadPlugin.btn.off"),
          close: this.$t("Vehicle.uploadPlugin.btn.close"),
          back: this.$t("Vehicle.uploadPlugin.btn.back"),
          save: this.$t("Vehicle.uploadPlugin.btn.save"),
        },
        error: {
          onlyImg: this.$t("Vehicle.uploadPlugin.error.onlyImg"),
          outOfSize: this.$t("Vehicle.uploadPlugin.error.outOfSize"),
          lowestPx: this.$t("Vehicle.uploadPlugin.error.lowestPx"),
        },
      },
    };
  },
  //computed:{
  //formatDate() {
  //return moment(this.item.validFrom).local();
  //}
  //},
  created() {},
  methods: {
    enableEditMode(enabled) {
      this.editMode = enabled;
    },
    getItem() {
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/vehicle/${this.itemId}`
        )
        .then((response) => {
          this.item = response.data;
          this.cachedItem = Object.assign({}, this.item);
        })
        .catch((e) => {
          this.snackbar.show = "true";
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },

    reset() {
      this.enableEditMode(false);
      //this.$refs.form.reset();
      this.$refs.form.resetValidation();
      this.item = Object.assign({}, this.cachedItem);
    },

    submitForm() {
      const isValid = this.$refs.form.validate();
      //v SUBMIT neposielat "IMAGE", na to je samostatna API, aby sa to nemiesalo - treba dorobit
      if (isValid) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/vehicle`,
            this.item
          )
          .then((response) => {
            this.item = response.data;
            this.cachedItem = Object.assign({}, this.item);
            this.enableEditMode(false);

            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateSuccess");
            this.snackbar.color = "success";
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateError");
            this.snackbar.color = "error";
          });
      }
    },

    submitPicture() {
      //const isValid = this.$refs.form.validate();
      const isValid = this.imgDataUrl;
      this.paramsPicture = {
        vehicleId: this.item.id,
        //name: "x",
        image: this.imgDataUrl,
        type: "app",
      };
      if (isValid) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/vehicle/picture`,
            this.paramsPicture
          )
          .then((response) => {
            // this.item = response.data;
            // this.cachedItem = Object.assign({}, this.item);
            //this.enableEditMode(false);

            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateSuccess");
            this.snackbar.color = "success";
            this.item.image = this.imgDataUrl;
          })
          .catch((e) => {
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateError");
            this.snackbar.color = "error";
          });
      }
    },

    toggleShowPicture() {
      this.showPicture = !this.showPicture;
    },
    /**
     * crop success
     *
     * [param] imgDataUrl
     * [param] field
     */
    cropSuccess(imgDataUrl, field) {
      console.log("-------- crop success --------");
      this.imgDataUrl = imgDataUrl;
    },
    /**
     * upload success
     *
     * [param] jsonData  server api return data, already json encode
     * [param] field
     */
    cropUploadSuccess(jsonData, field) {
      console.log("-------- upload success --------");
      console.log(jsonData);
      console.log("field: " + field);
    },
    /**
     * upload fail
     *
     * [param] status    server api return error status, like 500
     * [param] field
     */
    cropUploadFail(status, field) {
      console.log("-------- upload fail --------");
      console.log(status);
      console.log("field: " + field);
    },
  },

  mounted() {
    this.itemId = this.$route.params.id;
    this.getItem();
  },
};
</script>
