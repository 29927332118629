<template>
  <div>
    <v-container>
      <v-row>
        <v-col cols="12" md="4">
          <v-text-field
            :label="$t('AccountClient.clientPhone')"
            v-model="clientPhone"
            filled
            dense
            :rules="[
              (v) => !!v || this.$t('msgRequiredField'),
              (v) => /^[+]*\d+$/.test(v) || this.$t('msgPhoneValidation'),
            ]"
            @keydown.enter="getClientByPhone()"
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="4">
          <v-btn class="my-2" color="success" @click.stop="getClientByPhone()">
            {{ $t("AccountClient.btnAddCustomer") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
    <v-data-table
      :loading="loadingData"
      :headers="gridHeaders"
      :items="gridItems"
      :items-per-page="40"
      class="elevation-1"
    >
      <template v-slot:[`item.status`]="{ item }">
        <v-chip small :color="getStatusColor(item.status)" dark label>
          {{ $t("AccountEnums.ClientStatus." + item.status) }}
        </v-chip>
      </template>

      <template v-slot:no-data>
        <v-btn color="error" @click="getAll()"> {{ $t("btnReload") }}</v-btn>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small color="info" @click="openEditDialog(item)">
          fa-pen
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog
      v-model="createDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{
          $t("AccountClient.dialogAssignTitle")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="formCreate">
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('AccountClient.clientId')"
                    dense
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createItem.clientId"
                    readonly
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('AccountClient.phone')"
                    dense
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    v-model="createItemInfoFields.phone"
                    readonly
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('AccountClient.nickname')"
                    dense
                    v-model="createItemInfoFields.nickname"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('AccountClient.mark')"
                    dense
                    v-model="createItemInfoFields.mark"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('AccountClient.note')"
                    dense
                    v-model="createItemInfoFields.note"
                    readonly
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    :label="$t('AccountClient.info')"
                    dense
                    v-model="createItemInfoFields.info"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="createDialog = false">{{
              $t("btnClose")
            }}</v-btn>
            <v-btn color="success" text @click.stop="submitCreateDialog()">{{
              $t("btnAssign")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="editDialog"
      transition="dialog-top-transition"
      max-width="600px"
    >
      <v-toolbar color="secondary" dark flat>
        <v-toolbar-title>{{
          $t("AccountClient.dialogEditTitle")
        }}</v-toolbar-title>
      </v-toolbar>
      <v-card>
        <v-form ref="formEdit">
          <v-card-text>
            <v-container>
              <v-row v-show="displayOnlyForAdmin" dense>
                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="editItem.accountId"
                    :label="$t('AccountClient.accountId')"
                    filled
                    dense
                    hide-details
                    required
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    readonly
                  ></v-text-field>
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    v-model="editItem.clientId"
                    :label="$t('AccountClient.clientId')"
                    hide-details
                    filled
                    dense
                    required
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    readonly
                  ></v-text-field>
                </v-col>
              </v-row>

              <v-row dense>
                <v-col cols="12">
                  <v-select
                    v-model="editItem.status"
                    :label="$t('AccountClient.status')"
                    filled
                    dense
                    :items="$t('AccountEnums.ClientStatusArray')"
                    :rules="[(v) => !!v || $t('msgRequiredField')]"
                    required
                  ></v-select>
                </v-col>

                <v-col cols="12">
                  <v-text-field
                    v-model="editItem.validFrom"
                    :label="$t('AccountClient.validFrom')"
                    filled
                    dense
                    :readonly="!displayOnlyForAdmin"
                  ></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="editItem.validTo"
                    :label="$t('AccountClient.validTo')"
                    filled
                    dense
                    :readonly="!displayOnlyForAdmin"
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="error" text @click.stop="editDialog = false">{{
              $t("btnClose")
            }}</v-btn>
            <v-btn color="success" text @click.stop="submitEditDialog()">{{
              $t("btnSave")
            }}</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>

    <v-snackbar
      v-model="snackbar.show"
      :timeout="snackbar.timeout"
      right
      value="true"
      :color="snackbar.color"
    >
      {{ snackbar.message }}
    </v-snackbar>
  </div>
</template>

<script>
import axios from "axios";
import { Status, StatusClientColor } from "../enums/AccountEnum";
import { Country } from "../enums/CountryEnum";

export default {
  props: {
    accountId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      //enums
      Status,
      displayOnlyForAdmin: false,
      StatusClientColor,
      gridHeaders: [
        {
          text: this.$t("AccountClient.tableHeaders.actions"),
          value: "actions",
          sortable: false,
        },
        {
          text: this.$t("AccountClient.tableHeaders.clientId"),
          value: "clientId",
        },
        { text: this.$t("AccountClient.tableHeaders.phone"), value: "phone" },
        {
          text: this.$t("AccountClient.tableHeaders.nickname"),
          value: "nickname",
        },
        { text: this.$t("AccountClient.tableHeaders.mark"), value: "mark" },
        { text: this.$t("AccountClient.tableHeaders.status"), value: "status" },
        {
          text: this.$t("AccountClient.tableHeaders.validFrom"),
          value: "validFrom",
        },
        {
          text: this.$t("AccountClient.tableHeaders.validTo"),
          value: "validTo",
        },
      ],
      gridItems: [],

      loadingData: false,

      //Uprava zákazníka
      editItem: {},
      editDialog: false,

      //Vyhladanie zakaznika v DB
      clientPhone: null,
      clientByPhoneSearchData: {
        phone: null,
      },

      //Vytvorenie noveho zakaznika - prepojenie
      createDefaultItem: {
        //id	:null,
        accountId: null,
        clientId: null,
      },
      createItem: {},

      //Informativne polia o zakaznikovi
      createDefaultItemInfoFields: {
        //id	:null,
        phone: null,
        nickname: null,
        mark: null,
        info: null,
        note: null,
      },
      createItemInfoFields: {},

      createDialog: false,

      snackbar: {
        show: false,
        message: "OK",
        color: "success",
        timeout: 2000,
      },
    };
  },

  methods: {
    getUserRole() {
      let enabledRoles = ["ADMIN", "SUPPORT"];
      let userRoles = this.$store.state.user.roleList;
      this.displayOnlyForAdmin =
        userRoles.some((a) => (enabledRoles.includes(a) ? true : false)) ===
        true;
    },

    getAll() {
      this.loadingData = true;
      axios
        .get(
          process.env.VUE_APP_API_BASE_URL +
            `/dispatching/taxiservice-account/` +
            this.accountId +
            `/client`
        )
        .then((response) => {
          this.loadingData = false;
          this.gridItems = response.data;
        })
        .catch((e) => {
          console.log(e);
          this.loadingData = false;
          this.snackbar.show = true;
          this.snackbar.message = this.$t("msgGetDataError");
          this.snackbar.color = "error";
        });
    },

    getClientByPhone() {
      console.log(this.clientPhone);
      this.clientByPhoneSearchData.phone = null;
      if (this.clientPhone.length == 0 || !this.clientPhone) {
        alert(this.$t("AccountClient.msgPhoneMissing"));
      } else if (this.clientPhone.substring(0, 1) != "+") {
        alert(this.$t("AccountClient.msgPhoneInvalid"));
      } else if (this.clientPhone.length < 11) {
        alert(this.$t("AccountClient.msgPhoneShort"));
      } else if (isNaN(this.clientPhone.substring(1, 20))) {
        alert(this.$t("AccountClient.msgPhoneOnlyNumbers"));
      } else if (
        this.clientPhone.substring(1, 2) == "+" ||
        this.clientPhone.substring(1, 2) == "-"
      ) {
        alert(this.$t("AccountClient.msgPhoneSecondCharIsNotNumber"));
      } else {
        //this.animateProgressBar("start"),
        //Vynulovanie poli
        this.createItem = Object.assign({}, this.createDefaultItem);
        this.createItemInfoFields = Object.assign(
          {},
          this.createDefaultItemInfoFields
        );
        this.clientByPhoneSearchData.phone = this.clientPhone;
        axios
          .get(
            process.env.VUE_APP_API_BASE_URL + `/dispatching/client/byPhone`,
            {
              params: this.clientByPhoneSearchData,
            }
          )
          .then((response) => {
            //this.animateProgressBar("ok"), (this.items = response.data);
            if (response.data.status == "OK") {
              //Ak pridu data a klient existuje, tak umoznit vytvorenie objednavky a editaciu udajov o klientovi
              //this.createClientDialog = false;
              var responseData = response.data.data;
              this.createItemInfoFields.phone = responseData.phone;
              this.createItemInfoFields.nickname = responseData.nickname;
              this.createItemInfoFields.mark = responseData.mark;
              this.createItemInfoFields.note = responseData.note;
              //Vytvorenie infa o zakazanikovi
              var accountAlreadyExists = "";
              //console.log("accountCounter", this.clientData.accountList.length);
              if (responseData.accountList.length > 0) {
                //check if there is account with id = this.accountId
                for (var i = 0; i < responseData.accountList.length; i++) {
                  if (responseData.accountList[i].id == this.accountId) {
                    accountAlreadyExists =
                      " | \uD83C\uDFE2" + responseData.accountList[i].name;

                    //Oznamenie o neexistencii klienta
                    this.snackbar.show = true;
                    this.snackbar.message = this.$t(
                      "AccountClient.msgCustomerAlreadyAssigned"
                    );
                    this.snackbar.color = "error";
                  }
                }
              }
              this.createItemInfoFields.info =
                responseData.notification +
                " | " +
                responseData.rating +
                " | " +
                responseData.driveCounter +
                accountAlreadyExists;

              //doplnenie dat na odoslanie requestu na vytvorenie prepojenia
              this.createItem.accountId = this.accountId;
              this.createItem.clientId = responseData.id;
              //zobraziť okno na prepojenie zakaznika
              this.createDialog = true;
            } else if (response.data.status == "FAILED") {
              this.createItem.clientId = null;
              //Oznamenie o neexistencii klienta
              this.snackbar.show = true;
              this.snackbar.message = this.$t(
                "AccountClient.msgCustomerNotExists"
              );
              this.snackbar.color = "error";
            } else {
              this.createItem.clientId = null;
              //Oznámenie o chybe
              this.snackbar.show = true;
              this.snackbar.message = this.$t("msgGetDataError");
              this.snackbar.color = "error";
            }
          })
          .catch((e) => {
            console.log(e);
            //this.animateProgressBar("error"), this.errors.push(e);
            this.snackbar.show = true;
            this.snackbar.message = this.$t("msgGetDataError");
            this.snackbar.color = "error";
          });
      }
    },
    submitCreateDialog() {
      const isValid = this.$refs.formCreate.validate();
      if (isValid) {
        axios
          .post(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/taxiservice-account/client`,
            this.createItem
          )
          .then((response) => {
            this.createDialog = false;
            this.getAll();
            if (
              response.data.status == "FAILED" &&
              response.data.message == "CLIENT_NOT_OWNED"
            ) {
              this.snackbar.show = "true";
              this.snackbar.message = this.$t(
                "AccountClient.msgCustomerNotAddedNoCustomer"
              );
              this.snackbar.color = "error";
            } else if (
              response.data.status == "FAILED" &&
              response.data.message == "ALREADY_EXISTS"
            ) {
              this.snackbar.show = "true";
              this.snackbar.message = this.$t(
                "AccountClient.msgCustomerNotAddedAlreadyAdded"
              );
              this.snackbar.color = "error";
            } else if (response.data.status == "OK") {
              this.snackbar.show = "true";
              this.snackbar.message = this.$t(
                "AccountClient.msgCustomerSuccessfullyAdded"
              );
              this.snackbar.color = "success";
            } else {
              this.snackbar.show = "true";
              this.snackbar.message = this.$t("msgCreateError");
              this.snackbar.color = "error";
            }
          })
          .catch((e) => {
            console.error(e);
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgCreateError");
            this.snackbar.color = "error";
          });
      }
    },

    openEditDialog(item) {
      this.editItem = Object.assign({}, item);
      this.editDialog = true;
    },
    submitEditDialog() {
      const isValid = this.$refs.formEdit.validate();
      //console.log("editItem", this.editItem);
      if (isValid) {
        axios
          .put(
            process.env.VUE_APP_API_BASE_URL +
              `/dispatching/taxiservice-account/client`,
            this.editItem
          )
          .then((response) => {
            this.editDialog = false;
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateSuccess");
            this.snackbar.color = "success";
            this.getAll();
          })
          .catch((e) => {
            console.log(e);
            this.snackbar.show = "true";
            this.snackbar.message = this.$t("msgUpdateError");
            this.snackbar.color = "error";
          });
      }
    },
    getStatusColor(status) {
      return this.StatusClientColor[status];
    },
  },
  mounted() {
    this.getUserRole();
    this.getAll();
  },
};
</script>
